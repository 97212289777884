<template>
  <brand-discount :shop-data="discountsData" img-height="4.375rem" />
</template>

<script>
import BrandDiscount from '@/components/BrandDiscount.vue'
export default {
  name: '',
  components: {
    'brand-discount': BrandDiscount
  },
  props: {
  },
  data () {
    return {
      discountsData: [
        {
          title: 'Solid Gold Chunky Huggie Hoops',
          imageUrl: '/images/index_home/fake/Discount_1.png',
          content: "These chunky solid gold hoops are both classic and trendy, and they have an unreal glow when they hit the light.  They're versatile enough that anyone can wear them making them a safe bet for gifting!"
        },
        {
          title: 'Musical Moonstone Pendant Necklace',
          imageUrl: '/images/index_home/fake/Discount_2.png',
          content: 'Like music to our ears.  The Musical Moonstone Pendant Necklace features a crescent moon and sunburst design with an extra glowy rainbow moonstone center.'
        },
        {
          title: 'Our Top Gift Picks For Your Valentine',
          imageUrl: '/images/index_home/fake/Discount_3.png',
          content: 'Love is the air, and so is the anxiety around picking the perfect Valentine’s Day gift. Whether it’s for your significant other or a Galentine’s day gift for your bff, we’re all about showing some extra love to our favorite people during V-day season.'
        },
        {
          title: 'Delicate with signature celestial style.',
          imageUrl: '/images/index_home/fake/Discount_4.png',
          content: 'The Celestial Ring from Elizabeth Stone features crescent moon and CZ encrusted starburst cutouts set between yellow gold bands.'
        },
        {
          title: 'Emma Heart Ring with White Opal',
          imageUrl: '/images/index_home/fake/Discount_5.png',
          content: "Stunning and sweet, the Emma Heart Ring with White Opal feature gorgeous opals hearts surrounded by trillion cut and baguette cut CZ's. A cocktail ring for the ages."
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
</style>
