<template>
  <div>
    <!-- 登录组件 -->
    <discounts-login />

    <!-- <discounts-login v-if="$store.state.user"/> -->
    <!-- 未登录组件 -->

    <!-- <discounts-logout v-else /> -->
  </div>
</template>

<script>
import DiscountsLogout from './components/DiscountsLogout.vue'
import DiscountsLogin from './components/DiscountsLogin.vue'
export default {
  name: '',
  components: {
    'discounts-logout': DiscountsLogout,
    'discounts-login': DiscountsLogin,
  },
  data() {
    return {}
  },
  mounted() {},
  created() {},
  methods: {},
}
</script>

<style lang="less" scoped>
</style>
