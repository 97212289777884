<template>
  <div>
    <div class="content">
      <div class="brand-item" v-for="(item, index) in shopData" :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="image">
          <img
            :src="require('@/assets' + item.imageUrl)"
            :style="{ height: imgHeight, borderRadius: imgRadius }"
          />
        </div>
        <div class="content">{{ item.content }}</div>
        <div class="extension">
          <span class="left">Extension</span>
          <i class="right">
            <van-icon name="arrow" />
          </i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    shopData: {
      type: Array,
      required: true
    },
    imgHeight: {
      type: String,
      default: '6.25rem'
    },
    imgRadius: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
.content {
  padding: 100px 0;
  .brand-item {
    padding: 0 30px;
    align-items: center;
    margin: 10px;
    background-color: #fff;
    .title {
      font-family: "PingFang-Medium";
      font-size: 31px;
      padding: 18px 0 16px 0;
    }
    .image {
        width: 100%;
      img {
        width: 100%;
      }
    }
    .content {
      font-family: "PingFang-Regular";
      padding: 14px 0 12px 0;
      font-size: 25px;
      color: #595959;
    }
    .extension {
      font-family: "PingFang-Regular";
      display: flex;
      justify-content: space-between;
      font-size: 25px;
      color: #595959;
      border-top: 1px solid #e8e8e8;
      padding: 20px 0;
    }
  }
}
</style>
